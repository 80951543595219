import * as React from "react"
import { LayoutFlat } from "../../components/flat/layout"
import { Link } from "gatsby";
import useInView from "react-cool-inview";

const BlogItemFlat = ({ title }) => {
    const { observe, inView } = useInView();
    return (
        <div className="c-blog-listing__item {{class}}" ref={observe}>
            <Link className={inView ? "c-blog-listing__item-link is-inview" : "c-blog-listing__item-link"} to="/flat/insights-article">
                <div className="c-blog-listing__item-media-container">
                    <div className="c-blog-listing__item-media-wrapper">
                        <img src="https://picsum.photos/320/180" alt="" className="c-blog-listing__item-media" loading="lazy"/>
                    </div>
                    <div className="c-blog-listing__item-category">Category name</div>
                </div>
                <h3 className="c-blog-listing__item-title">{title}</h3>
                <time className="c-blog-listing__item-date" dateTime="2021-06-12">12 June 2021</time>
            </Link>
        </div>
    )
}

const InsightsArticleFlat = () => (
    <LayoutFlat>
        <div className="c-article">
            <div className="container">
                <div className="row">
                    <div className="c-article__main">
                        <div className="c-article__header">
                            <h1 className="c-article__header-title">Article title would go here</h1>
                            <div className="c-article__header-meta">
                                <div className="c-article__header-author-img-container">
                                    <img src="https://picsum.photos/320/180" alt="" className="c-article__header-author-img" loading="lazy"/>
                                </div>
                                <div className="c-article__header-meta-container">
                                    <div className="c-article__header-author-info">
                                        <div className="c-article__header-author">John Smith</div>
                                        <time className="c-article__header-date" dateTime="2021-06-12">12 June 2021</time>
                                    </div>
                                    <div className="c-article__header-contact">
                                        <div className="c-article__header-contact-title">Contact John Smith</div>
                                        <ul className="c-article__header-contact-items">
                                            <li className="c-article__header-contact-item">
                                                <a href="http://www.linkedin.com" className="c-article__header-contact-link" rel="noreferrer" target="_blank">
                                                    <svg fill="none" viewBox="0 0 16 16" className="c-article__header-contact-icon"><path fillRule="evenodd" clipRule="evenodd" d="M13.635 13.634h-2.373V9.92c0-.886-.016-2.024-1.234-2.024-1.234 0-1.422.966-1.422 1.961v3.777H6.235V5.998h2.274v1.044h.033c.317-.6 1.09-1.233 2.245-1.233 2.404 0 2.847 1.58 2.847 3.637v4.188ZM3.559 4.955a1.376 1.376 0 1 1-.003-2.753 1.377 1.377 0 0 1 .003 2.754v-.001Zm-1.19 8.679h2.376V5.998H2.37v7.636ZM14.816 0H1.18C.529 0 0 .516 0 1.154v13.691C0 15.482.53 16 1.18 16h13.636c.653 0 1.184-.518 1.184-1.155V1.154C16 .516 15.469 0 14.816 0Z" fill="#1A1A1A"/></svg>
                                                    <span className="u-visually-hide">View LinkedIn profile</span>
                                                </a>
                                            </li>
                                            <li className="c-article__header-contact-item">
                                                <a href="mailto:john@smith.com" className="c-article__header-contact-link">
                                                    <svg fill="none" viewBox="0 0 16 16" className="c-article__header-contact-icon"><path fillRule="evenodd" clipRule="evenodd" d="M0 2h16v12H0V2Zm1.347 4.417v6.213h13.305V6.323L8.006 9.798 1.347 6.417Zm0-1.533 6.65 3.377 6.655-3.48V3.372H1.347v1.512Z" fill="#1A1A1A"/></svg>
                                                    <span className="u-visually-hide">Email John Smith</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="c-article__body cms-content">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque hendrerit purus ex,
                                eget molestie quam rutrum vitae. In egestas faucibus magna, vel malesuada mi tempor
                                a.</p>

                            <h2>H2 Heading</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque hendrerit purus ex,
                                eget molestie quam rutrum vitae. In egestas faucibus magna, vel malesuada mi tempor
                                a.</p>

                            <h3>H3 Heading</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque hendrerit purus ex,
                                eget molestie quam rutrum vitae. In egestas faucibus magna, vel malesuada mi tempor
                                a.</p>

                            <h4>H4 Heading</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque hendrerit purus ex,
                                eget molestie quam rutrum vitae. In egestas faucibus magna, vel malesuada mi tempor
                                a.</p>

                            <h5>H5 Heading</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque hendrerit purus ex,
                                eget molestie quam rutrum vitae. In egestas faucibus magna, vel malesuada mi tempor
                                a.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque hendrerit purus ex,
                                eget molestie quam rutrum vitae. In egestas faucibus magna, vel malesuada mi tempor
                                a.</p>

                            <ul>
                                <li>This is a list item in an unordered list</li>
                                <li>An unordered list is a list in which the sequence of items is not important
                                    <ul>
                                        <li>This is a list item in an unordered list</li>
                                        <li>An unordered list is a list in which the sequence of items is not
                                            important
                                        </li>
                                    </ul>
                                </li>
                                <li>This is a list item in an unordered list</li>
                                <li>An unordered list is a list in which the sequence of items is not
                                    important
                                </li>
                            </ul>

                            <ol>
                                <li>This is a list item in an ordered list</li>
                                <li>An ordered list is a list in where the sequence of items is important</li>
                            </ol>

                            <p><strong>Bold text</strong></p>

                            <p><em>Italic text</em></p>

                            <p><a href="/">Anchor link</a></p>
                        </div>
                    </div>
                    <div className="c-article__sidebar">
                        <div className="c-article__share">
                            <div className="c-article__share-title" id="share-label">Share this story</div>
                            <ul className="c-article__share-items" aria-labelledby="share-label">
                                <li className="c-article__share-item">
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=" target="_blank" className="c-article__share-link" rel="noreferrer">
                                        <span className="u-visually-hide">Share on LinkedIn</span>
                                        <svg className="c-article__share-link-icon" viewBox="0 0 32 32"><path d="M27.263 27.269h-4.739v-7.425c0-1.771-0.036-4.049-2.469-4.049-2.471 0-2.848 1.927-2.848 3.919v7.556h-4.739v-15.269h4.552v2.081h0.061c0.636-1.2 2.183-2.467 4.493-2.467 4.801 0 5.689 3.16 5.689 7.273zM7.116 9.911c-1.525 0-2.751-1.235-2.751-2.753 0-1.517 1.227-2.751 2.751-2.751 1.52 0 2.752 1.233 2.752 2.751 0 1.519-1.233 2.753-2.752 2.753zM9.492 27.269h-4.752v-15.269h4.752zM29.633 0h-27.272c-1.305 0-2.361 1.032-2.361 2.305v27.389c0 1.275 1.056 2.305 2.361 2.305h27.268c1.304 0 2.371-1.031 2.371-2.305v-27.389c0-1.273-1.067-2.305-2.371-2.305z"></path></svg>
                                    </a>
                                </li>
                                <li className="c-article__share-item">
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=" target="_blank" className="c-article__share-link" rel="noreferrer">
                                        <span className="u-visually-hide">Share on Facebook</span>
                                        <svg className="c-article__share-link-icon" viewBox="0 0 32 32"><path d="M31.997 15.999c0-8.836-7.163-15.999-15.999-15.999s-15.999 7.163-15.999 15.999c0 7.985 5.851 14.604 13.499 15.804v-11.18h-4.062v-4.625h4.062v-3.525c0-4.010 2.389-6.225 6.043-6.225 1.75 0 3.581 0.313 3.581 0.313v3.937h-2.017c-1.987 0-2.607 1.233-2.607 2.498v3.001h4.437l-0.709 4.625h-3.728v11.18c7.649-1.2 13.499-7.819 13.499-15.804z"></path></svg>
                                    </a>
                                </li>
                                <li className="c-article__share-item">
                                    <a href="https://twitter.com/intent/tweet?url=" target="_blank" className="c-article__share-link" rel="noreferrer">
                                        <span className="u-visually-hide">Share on Twitter</span>
                                        <svg className="c-article__share-link-icon" viewBox="0 0 32 28"><path fill-rule="evenodd" clip-rule="evenodd" d="M32 3.7971a12.7016 12.7016 0 01-3.7697 1.0726c1.3561-.8424 2.3967-2.1783 2.8869-3.7696-1.2697.78-2.6728 1.3475-4.1716 1.6518-1.1946-1.3242-2.9019-2.151-4.7915-2.151-3.6231 0-6.5626 3.052-6.5626 6.8158 0 .5343.0563 1.053.169 1.5523-5.4563-.2847-10.2929-2.9955-13.5329-7.124-.5653 1.0102-.8884 2.1823-.8884 3.4304 0 2.3636 1.1589 4.4502 2.9207 5.673-1.0744-.0332-2.0886-.3432-2.9752-.8503v.0839c0 3.3036 2.2633 6.0591 5.2686 6.6832-.5504.1599-1.1307.2399-1.73.2399-.4225 0-.8357-.041-1.2358-.121.8358 2.7069 3.2588 4.6785 6.1325 4.7311-2.2464 1.8293-5.0788 2.9194-8.1535 2.9194-.5297 0-1.0537-.0312-1.5665-.0936 2.9057 1.9307 6.358 3.0598 10.0637 3.0598 12.0773 0 18.6794-10.3846 18.6794-19.3924a22.81 22.81 0 00-.0169-.8834C30.009 6.3636 31.1229 5.1642 32 3.797z" fill="#000000"></path></svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="c-article__related">
                            <div className="c-article__related-title">Related insights</div>
                            <div className="c-blog-listing c-blog-listing--article">
                                <div className="row">
                                    <BlogItemFlat title="Title would go here" />
                                    <BlogItemFlat title="Title would go here" />
                                    <BlogItemFlat title="Title would go here" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutFlat>
)

export default InsightsArticleFlat
